/* eslint-disable jsx-a11y/iframe-has-title */
import { Button, Row, Alert } from "react-bootstrap";
import { colors, apiURL } from "../config/env";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import FullScreenSpinner from '../component/FullScreenSpinner';
import { step11PreQualifiedLoadedEvent, step11PreQualifiedCompletedEvent } from '../component/FacebookPixelEvents';

function FormSuccess({ activeKey, setActiveKey, formData, setFormData })
{
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() =>
    {
        step11PreQualifiedLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const handleClick = async () =>
    {
        setLoading(true);
        setErrorMessage(null);

        try
        {
            const xano_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;

            const resposne_xano = await axios.get(xano_uri);
            const zoho_record_id = resposne_xano.data.zoho_record_id;


            const zoho_uri = `${apiURL}/update_stage/${zoho_record_id}`;
            await axios.post(zoho_uri, { stage: 'IRS Authorization -Started' });

            const newActiveKey = +activeKey + 1;

            step11PreQualifiedCompletedEvent();

            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);

            setLoading(false);
        }
        catch (error)
        {
            setLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>You have been Pre-Qualified!</h2>

                <div className="d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969103409?h=0f73829d6e&title=0&byline=0&portrait=0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>

                <br></br>
                <br></br>

                <p style={{ textAlign: "center" }}>
                    {" "}
                    <strong>Congratulations!</strong> We are pleased to inform you that you have been pre-qualified for the Self-Employed Tax Credit. Here are the next steps and what you can expect moving forward:
                </p>

                <ol className="mt-3">
                    <li className="mt-3"><p><strong>Complete Your Application:</strong> You will now be guided to finish your application by granting us consent to retrieve your tax returns. This is essential for us to calculate your refund amount accurately.
                    </p></li>
                    <li className="mt-3"><p><strong>Verify Your Identification:</strong> You will be directed to verify your identification and Social Security number through our secure portal, in partnership with Tax Status.
                    </p></li>
                    <li className="mt-3"><p><strong>IRS Verification:</strong> Upon granting us consent, it will take the IRS up to 10 days to verify your identity and send us your documents. This process ensures we receive all the necessary information to automatically maximize your tax credit calculations without requiring you to upload any documents.
                    </p></li>
                    <li className="mt-3"><p><strong>Stay Informed:</strong> Throughout the entire process, you will receive updates on your application status via email and/or text.
                    </p></li>
                </ol>

                <p>You will complete steps 1-3 right now over the next few minutes. Remember if you don't complete the esignature step it will delay us in processing your application. </p>


                <Row style={{ justifyContent: 'center' }}>
                    <Button
                        className="normal-button"
                        onClick={handleClick}
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                    >
                        Finish your Application
                    </Button>
                </Row>


                <br></br>
                <p>
                    We are here to assist you at every step. If you have any questions or need further assistance, please do not hesitate to contact our support team at
                    <a href="mailto:help@automatedtaxcredits.com "> help@automatedtaxcredits.com </a>.
                </p>
                <br />
                <p>Thank you for choosing us to assist you with your Self-Employed Tax Credit application.</p>


                {errorMessage && (
                    <Row>
                        <Alert variant="danger" onClose={() =>
                        {
                            setErrorMessage(null);
                        }} dismissible className="mt-3">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    </Row>
                )}

                <Row style={{ justifyContent: 'center', marginTop: "12px" }}>
                    <Button
                        className="normal-button"
                        onClick={handleClick}
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                    >
                        Finish your Application
                    </Button>
                </Row>

                <br />
                {/* <p>NOTES: [SETC Pros] = [Client Company Name]</p> */}
                {/* <p>Support@setcpros.com = <a href="mailto:Support@setcpros.com">[Client Company Support EMAIL]</a></p> */}

                {loading && (
                    <Row>
                        <FullScreenSpinner />
                    </Row>
                )}

            </div>
        </div>
    );
}

export default FormSuccess;
