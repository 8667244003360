import React, { useEffect } from "react";
import { getWithExpiry } from '../component/utility';
import SecureLogo from '../component/SecureLogo';
import { step15LetUsGetToWorkNowLoadedEvent } from '../component/FacebookPixelEvents';

function FinalSuccessPage({ activeKey, setActiveKey, formData, setFormData })
{
    useEffect(() =>
    {
        step15LetUsGetToWorkNowLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>Well Done… Let Us Get to Work Now!</h2>

                <div className="d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969195795?h=e4f78a2328&title=0&byline=0&portrait=0" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>

                <br></br>
                <br></br>
                <p>Thank you for completing the client intake process. </p>
                <br></br>
                <p>Our team, which is now your team, is getting to work to analyze your tax transcripts and help you maximize the available tax credits. </p>
                <br></br>
                <p>Remember it can take the <strong>IRS up to 15 business days to release your transcripts</strong> to our team to analyze. </p>
                <br></br>
                <p>Once we have your IRS transcripts our team will analyze your transcripts to determine exactly how much you are qualified to claim for the Self Employment Tax Credit along with any other available tax credits you qualify for. </p>
                <br></br>
                <p>Our team will present your available credits at this point, usually within 1 business day of receiving the transcripts from the IRS. If you choose to proceed with the filing of your tax credits our team will then prepare your documents for e-filing with the IRS.</p>
                <br></br>
                <p>It’s only when we confirm you have tax credits available that you’ll pay the document processing fees and agree to the client service agreement on how we collect our fees.</p>

                <br></br>
                <h3>How long does it take to receive the Tax Credits? </h3>
                <p>It can take up to 15 weeks or more for the IRS to process and payout your Tax Credits. </p>

                <br></br>
                <h3>How much does SETC filing cost?</h3>
                <p>Document Processing Fee: $199</p>
                <p>Tax Credit Success Fee: 20% of available tax credit paid on success. </p>

                <br></br>
                <p>Checking your eligibility and potential refund amount is completely free with us. We only get paid once you successfully receive your Self-Employed Tax Credit refund. Here’s the process:</p>
                <ul>
                    <li>No Upfront Costs: We don’t charge any fees initially until we validate you’re qualified and the tax credits are available to you. </li>
                    <li>Fee Upon Successful Refund: Our fee is deducted from your bank deposit after the IRS approves and sends your refund.</li>
                    <li>Secure Payments: We use secure and private payment processing.</li>
                </ul>

                <br></br>
                <h4>When do I pay any fees for a SETC tax refund?</h4>
                <p>You will only pay a fee after we secure a successful SETC tax refund for you. The fee will be automatically deducted from your refund once the IRS sends it. We do provide clients with an option to prepay their fees for an increased discount.</p>

                <br></br>
                <h4>Will there be a problem if I have already filed my 2021 tax return?</h4>
                <p>No problem! Our system will retrieve your tax data from the IRS. We will provide you with the maximum tax benefit once we receive this information.</p>

                <br></br>
                <h4>How much will I be charged for this service?</h4>
                <p>If you do not owe any back taxes – there are no upfront charges. You will be charged 20% of the total refund you receive from the IRS.</p>
                <br></br>
                <p>If you owe any back taxes – We will be sending a prepaid invoice before submitting your documents to the IRS. This will be at a lower % than the 20% mentioned above.</p>

                <br></br>
                <h4>How long will it take until I get my SETC tax refund?</h4>
                <p><strong>Initial Processing: </strong> After submitting your application, expect to receive IRS data within 3-5 business days. We will then calculate your estimated SETC refund amount.</p>

                <br></br>
                <p><strong>Refund Notification:</strong>Once we calculate your Refund amount, you will be notified of our fees on how to pay our fees based on if you or do not owe money to the IRS.</p>

                <br></br>
                <p><strong>If you do not have a balance with the IRS</strong> – we will only charge you after you have received your refund from the IRS.</p>

                <br></br>
                <p><strong>If you do have a balance with the IRS</strong> – a prepaid invoice at a discounted amount will be sent to you before sending out your SETC Refund.</p>

                <br></br>
                <p><strong>IRS Processing:</strong>An email will be sent allowing you to access and print necessary IRS forms through our Customer Portal. Complete, sign, and mail these forms to the IRS. Processing typically takes 4-16 weeks.</p>

                <br></br>
                <p><strong>Refund Delivery:</strong>Based on recent IRS information, 2021 refunds will be issued by direct deposit.</p>

                <br></br>
                <div className="d-flex justify-content-center">
                    <SecureLogo />
                </div>
            </div>
        </div>
    );
}

export default FinalSuccessPage;
