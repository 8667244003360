import React from "react";
import { Row, Form } from "react-bootstrap";

const Question = ({
  title,
  groupName,
  value,
  onChange,
  values = ["Yes", "No"],
}) => {
  const classes = {
    fontSize: "15px",
  };

  return (
    <Row className="question-group">
      <div className="question-title">
        <strong>{title}</strong>
      </div>
      <div key="inline-radio" className="mb-3">
        {values.map((val, index) => (
          <Form.Check
            key={index}
            classnames={classes}
            label={val}
            value={val}
            name={groupName}
            type="radio"
            checked={value === val}
            onChange={(event) => onChange(event)}
          />
        ))}
      </div>
    </Row>
  );
};

export default Question;
