import React, { useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { APP_BASE_URL, colors } from "../config/env";
import { estimaterSuccessLoadedEvent, claimTaxCreditEvent } from '../component/FacebookPixelEvents';


function EstimatorSuccess({ activeKey, setActiveKey, formData, setFormData })
{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    useEffect(() =>
    {
        estimaterSuccessLoadedEvent();
    }, []);

    const claimCredits = (e) =>
    {
        e.preventDefault();

        claimTaxCreditEvent();

        const payload = {
            EstimatedValue: formData.EstimatedValue,
            salespersonId: formData.salespersonId
        };

        const token = btoa(JSON.stringify(payload));

        const url = `${APP_BASE_URL}/form?token=${token}`;
        const ref = window.open(url, '_blank');
    };

    return (
        <div className="container">
            <h2 className="heading_emp" style={{ marginTop: 20, textAlign: "center" }}>Your Estimated Self-Employed Tax Credit</h2>

            <Row>
                <p style={{ textAlign: "center" }}>According to the information you provided, it appears you could qualify for a total tax refund in the amount of: </p>
            </Row>

            <Row>
                <h1 className="heading_emp" style={{ textAlign: "center" }}>{formatter.format(formData.EstimatedValue)}</h1>
            </Row>

            <Row>
                <p>Claim your Self-Employed Tax Credit effortlessly with our AI-driven analysis of your tax transcripts. Our CPA-certified process ensures accuracy, and we provide an Audit Protection Guarantee for your peace of mind.</p>
            </Row>

            <Row style={{ justifyContent: 'center', marginTop: '16px' }}>
                <Button
                    className="normal-button"
                    onClick={claimCredits}
                    style={{
                        width: "auto",
                        backgroundColor: colors.theme,
                        borderColor: colors.theme,
                        fontFamily: "unset",
                        fontWeight: "bold"
                    }}
                >
                    Claim Your Tax Credit Now!
                </Button>
            </Row>

            <Row className="mt-4">
                <p><strong>REFUND DISCLAIMER:</strong> This federal tax refund estimate is not guaranteed and is for informational purposes only. Many factors go into calculating any federal refund from the IRS such as: 1. If you owe any back taxes; 2. If you’ve already claimed a full or partial tax credit for this program; 3. A full review of your tax return transcripts for accuracy; 4. The estimate is for informational use only and is reliant on the accuracy of the information submitted.</p>
            </Row>
        </div>
    );
}

export default EstimatorSuccess;
