import * as React from "react";
import { Row, Button, Col, Alert } from "react-bootstrap";
import { Dropzone, FileMosaic } from "@files-ui/react";
import axios from "axios";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import FullScreenSpinner from '../component/FullScreenSpinner';
import SecureLogo from '../component/SecureLogo';
import { colors } from '../config/env';
import { step12GovernmentIdentificationLoadedEvent } from '../component/FacebookPixelEvents';
import { Veriff } from '@veriff/js-sdk';


function DLUploader({ activeKey, setActiveKey, formData, setFormData })
{

    React.useEffect(() =>
    {
        step12GovernmentIdentificationLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    React.useEffect(() =>
    {

        const veriff = Veriff({
            apiKey: process.env.REACT_APP_VERIFF_API_KEY,
            parentId: 'veriff-root',
            onSession: function(err, response) {
                console.log(response);
                window.open(response.verification.url, '_blank');
            }
          });
          veriff.setParams({
            person: {
                givenName: ' ',
                lastName: ' ',
            },
            vendorData: `${formData.Email}`
          })
          veriff.mount({
            submitBtnText: 'Get verified',
            loadingText: 'Please wait...'
          });
    

        const handleBeforeUnload = (event) =>
        {
            event.preventDefault();
            event.returnValue = '';

            return '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () =>
        {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData.Email, formData.First_Name, formData.Last_Name]);
    


    return (
        <div style={{marginTop:'2.5rem'}}>
            <h3>We use Veriff for the ID verification, which is important to verify your identity.</h3>
            <div id='veriff-root' style={{margin:'auto', marginTop:'2rem'}}></div>
        </div>
        
    );
}

export default DLUploader;
