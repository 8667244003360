// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input:checked {
  background-color: #F6921E;
  border-color: #F6921E;
}
`, "",{"version":3,"sources":["webpack://./src/asset/css/custom-radio.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[".form-check-input:checked {\n  background-color: #F6921E;\n  border-color: #F6921E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
