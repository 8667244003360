import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SecureLogo from './SecureLogo';

function Footer()
{
    return (
        <footer className="pb-3 pt-5 border-top border-2" style={{ borderColor: "#77777770" }}>
            <Container>
                <Row>
                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
                        <img style={{ width: "200px" }} src="/full-logo.png" />
                        <p className="mt-4">
                            All Rights Reserved.
                        </p>
                    </Col>
                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-end justify-content-center">
                        <SecureLogo />

                        <div className="d-flex flex-row justify-content-center gap-4 mt-4">
                            <a href="https://automatedtaxcredits.com/terms-and-conditions/" target="_blank">TERMS & CONDITIONS</a>
                            <a href="https://automatedtaxcredits.com/privacy-policy/" target="_blank">PRIVACY POLICY</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
