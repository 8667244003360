import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../asset/css/nunito-font.css";
import "../asset/css/custom-radio.css";
import "./Landing.css";
import { getWithExpiry } from '../component/utility';
import queryString from 'query-string';
import Estimator from './Estimator';
import EstimatorSuccess from './EstimatorSuccess';

function LandingEstimator()
{
    const [activeKey, setActiveKeyRef] = useState(1);
    const [formData, setFormData] = useState({});
    const [optedIn, setOptedIn] = useState(false);

    const setActiveKey = (e) =>
    {
        setActiveKeyRef(e);
    };

    useEffect(() =>
    {
        const storedOptedIn = getWithExpiry("optedIn");
        const storedActiveKey = getWithExpiry("activeKey");
        const storedFormData = getWithExpiry("formData");

        if (storedOptedIn) setOptedIn(storedOptedIn);
        if (storedActiveKey) setActiveKey(storedActiveKey);
        if (storedFormData) setFormData(storedFormData);
    }, []);

    useEffect(() =>
    {
        const parsed = queryString.parse(window.location.search);
        const salespersonId = parsed.salespersonId || '';
        setFormData(prevFormData => ({ ...prevFormData, salespersonId }));
    }, []);

    const navItems = [
        { eventKey: 1, title: "Estimator" },
        { eventKey: 2, title: "EstimatorSuccess" },
    ];

    return (
        <Container className="text-center">
            <div className="form-body">
                <Tab.Container
                    defaultActiveKey={1}
                    activeKey={activeKey}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <Nav variant="tabs">
                        {navItems.map((item, idx) => (
                            <Nav.Item key={idx}>
                                <Nav.Link
                                    eventKey={item.eventKey}
                                    className={activeKey > item.eventKey ? "passed" : ""}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {item.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={1} title="Estimator">
                            <Estimator
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2} title="EstimatorSuccess">
                            <EstimatorSuccess
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Container>
    );
}

export default LandingEstimator;
