import React, { useState, useEffect } from "react";
import { Row, Form, Button, Col, Table } from "react-bootstrap";
import DateDropDown from "../component/DateDropDown";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step4PartOneSickLeaveCareYouProvidedToAnotherLoadedEvent, step4PartOneSickLeaveCareYouProvidedToAnotherCompletedEvent } from '../component/FacebookPixelEvents';

function FormC({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});

    useEffect(() =>
    {
        step4PartOneSickLeaveCareYouProvidedToAnotherLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};
        if (!formData.S3_Q2 || formData.S3_Q2?.some((d) => !d))
            initialError.S3_Q2 = "Please pick all dates";

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        } else
        {
            step4PartOneSickLeaveCareYouProvidedToAnotherCompletedEvent();

            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);
        }
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <div className="form-header">
                    <div className="form-title">
                        Number of days after December 31, 2020, and before April 1, 2021, you were unable to perform services as a self-employed individual because of certain coronavirus-related care you provided to another (10 days max).

                        <p style={{fontWeight: "bold", marginTop: "10px"}}>
                            See below for instructions
                        </p>
                    </div>
                </div>
            </Row>

            <Row className="date-select-picker">
                <DateDropDown
                    style={{ marginTop: 20, marginBottom: 20 }}
                    maxValue={10}
                    startDay={new Date("2021-01-02")}
                    endDay={new Date("2021-04-01")}
                    formData={formData}
                    onChange={(e) => setFormData({ ...formData, S3_Q2: e })}
                    error={error.S3_Q2}
                />
            </Row>

            <Row>
                <div className="form-button-group mt-3">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = +activeKey - 1;
                            setActiveKey(newActiveKey);
                            setWithExpiry("activeKey", newActiveKey, 50000); // Store activeKey with 72 hours expiry
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        Back
                    </Button>
                    <Button
                        className="next-button"
                        type="submit"
                        style={{ backgroundColor: colors.theme, borderColor: colors.theme, width: 100 }}
                    >
                        NEXT
                    </Button>
                </div>
            </Row>

            <Col className="mt-4 upper-divider italics">
                <p>Select the number of days in the period from January 1, 2021, through March 31, 2021, that you were unable to perform services as an eligible self-employed individual because of one or more of the following reasons:</p>

                <ul>
                    <li>You were caring for an individual who was subject to a federal, state, or local quarantine or isolation order related to COVID-19.</li>
                    <li>You were caring for an individual who was advised by a health care provider to self-quarantine due to concerns related to COVID-19.</li>
                    <li>You were caring for a son or daughter because the school or place of care for that child was closed or the childcare provider for that child was unavailable due to COVID-19 precautions.</li>
                </ul>

                <p>A son or daughter must generally be under 18 years of age or incapable of self-care because of a mental or physical disability.</p>
            </Col>
        </Form>
    );
}

export default FormC;
