import React, { useState, useEffect, act } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import FormFirst from "./FormFirst";
import FormA from "./FormA";
import FormB from "./FormB";
import FormC from "./FormC";
import FormD from "./FormD";
import FormE from "./FormE";
import FormF from "./FormF";
import FormG from "./FormG";
import FormH from "./FormH";
import ClientServicesAgreement from "./ClientServicesAgreement";
import FormSuccess from "./FormSuccess";
import "bootstrap/dist/css/bootstrap.min.css";
import "../asset/css/nunito-font.css";
import "../asset/css/custom-radio.css";
import "./Landing.css";
import DLUploader from './DLUploader';
import DLExtractedForm from './DLExtractedForm';
import ESignInstructions from './ESignInstructions';
import FinalSuccessPage from './FinalSuccessPage';
import { getWithExpiry } from '../component/utility';
import Footer from '../component/Footer';
import Header from '../component/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from "axios";


const saveProgress = async (formData, currentPage) =>
{
    try
    {
        if (!formData.Email)
        {
            console.warn('Email is required to save progress.');
            return;
        }

        const parsed = queryString.parse(window.location.search);
        const token = parsed.token;

        const progressData = {
            formData: JSON.stringify(formData),
            currentPage: currentPage,
            token: token,
            email: formData.Email,
        };

        const emailExistsResponse = await axios.get(`https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/email_exists_user_progress?email=${formData.Email}`);
        const emailExists = emailExistsResponse.data;

        if (emailExists)
        {
            await axios.patch(
                'https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/user_progress',
                progressData
            );
        } else
        {
            await axios.post(
                'https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/user_progress',
                progressData
            );
        }

    } catch (error)
    {
        console.error('Error saving progress:', error);
    }
};


const loadProgress = async (email) =>
{
    try
    {
        const response = await axios.get(`https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/get_user_progress?email=${email}`);
        if (response.status === 200)
        {
            return response.data;
        } else
        {
            console.error('Progress not found');
        }
    } catch (error)
    {
        console.error('Error loading progress:', error);
    }
};


function LandingForm()
{
    const navigate = useNavigate();
    const [activeKey, setActiveKeyRef] = useState(1);
    const [formData, setFormData] = useState({});
    const [optedIn, setOptedIn] = useState(false);
    const location = useLocation();


    useEffect(() =>
    {
        const storedOptedIn = getWithExpiry("optedIn");
        const storedActiveKey = getWithExpiry("activeKey");
        const storedFormData = getWithExpiry("formData");
        if (storedOptedIn)
        {
            setOptedIn(storedOptedIn);
        }
        if (storedActiveKey)
        {
            setActiveKey(storedActiveKey);
        }
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, []);

    // useEffect(() =>
    // {
    //     const handleUnload = async () =>
    //     {
    //         saveProgress(formData, activeKey);
    //     };
    //     window.addEventListener("beforeunload", handleUnload);
    //     return () =>
    //     {
    //         window.removeEventListener("beforeunload", handleUnload);
    //     };
    // }, [formData, activeKey]);

    useEffect(() =>
    {
        let email;
        try
        {
            email = location.state.email;
        }
        catch (err)
        {
            console.log('no email set');
            return;
        }

        loadProgress(email).then(data =>
        {
            console.log(data.formData);
            if (data)
            {
                setFormData(data.formData);
                setActiveKey(Number(data.currentPage));
            }
        });
    }, [navigate]);

    function decodeToken(token)
    {
        try
        {
            return JSON.parse(atob(token));
        } catch (e)
        {
            console.error("Invalid token:", e);
            return null;
        }
    }

    useEffect(() =>
    {
        const parsed = queryString.parse(window.location.search);
        const token = parsed.token;

        if (!token)
        {
            navigate('/estimator');
            return;
        }

        const payload = decodeToken(token);

        console.log('Payload: ', payload);

        if (!payload || !payload.EstimatedValue)
        {
            navigate('/estimator');
            return;
        }

        setFormData(prevFormData => ({ ...prevFormData, ...payload }));

    }, [navigate]);

    useEffect(() =>
    {
        // First script tag
        const inlineScript = document.createElement('script');
        inlineScript.text = `
          window.$zoho = window.$zoho || {};
          $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
        `;
        document.body.appendChild(inlineScript);

        // Second script tag
        const externalScript = document.createElement('script');
        externalScript.id = 'zsiqscript';
        externalScript.src = 'https://salesiq.zohopublic.com/widget?wc=siq8bd13c0535ef88555e76d61e96c357a39a1aac12aa1f3813e40b4a17af334a54';
        externalScript.defer = true;
        document.body.appendChild(externalScript);

        // Cleanup function to remove scripts when component unmounts
        return () =>
        {
            document.body.removeChild(inlineScript);
            document.body.removeChild(externalScript);
        };
    }, []);

    const setActiveKey = (e) =>
    {
        setActiveKeyRef(e);
        if (activeKey < e)
        {
            saveProgress(formData, e);
        }

        const scrollToTop = () =>
        {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
    };

    const navItems = [
        { eventKey: 1, title: "FormFirst" },
        { eventKey: 2, title: "FormA" },
        { eventKey: 3, title: "FormB" },
        { eventKey: 4, title: "FormC" },
        { eventKey: 5, title: "FormD" },
        { eventKey: 6, title: "FormE" },
        { eventKey: 7, title: "FormF" },
        { eventKey: 8, title: "FormG" },
        { eventKey: 9, title: "FormH" },
        { eventKey: 10, title: "ClientServicesAgreement" },
        { eventKey: 11, title: "FormSuccess" },
        { eventKey: 12, title: "Upload Driving Licenses" },
        { eventKey: 13, title: "Driving Licenses Extracted Data" },
        { eventKey: 14, title: "ESign Instructions" },
        { eventKey: 15, title: "Final Success Page" },
    ];

    return (
        <Container className="text-center">
            <Header />

            <div className="form-body">
                <Tab.Container
                    defaultActiveKey={1}
                    activeKey={activeKey}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <Nav variant="tabs">
                        {navItems.map((item, idx) => (
                            <Nav.Item key={idx}>
                                <Nav.Link
                                    eventKey={item.eventKey}
                                    className={activeKey > item.eventKey ? "passed" : ""}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {item.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={1}>
                            <FormFirst
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2}>
                            <FormA
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={3}>
                            <FormB
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={4}>
                            <FormC
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={5}>
                            <FormD
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={6}>
                            <FormE
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={7}>
                            <FormF
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={8}>
                            <FormG
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={9}>
                            <FormH
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={10}>
                            <ClientServicesAgreement
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={11}>
                            <FormSuccess
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={12}>
                            <DLUploader
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={13}>
                            <DLExtractedForm
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={14}>
                            <ESignInstructions
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={15}>
                            <FinalSuccessPage
                                className="form-content"
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            <Footer />
        </Container>
    );
}

export default LandingForm;
