export const estimatorLoadedEvent = () => window.fbq('track', 'EstimatorLoaded');
export const estimatedValueCalculatedEvent = () => window.fbq('track', 'EstimatedValueCalculated');

export const estimaterSuccessLoadedEvent = () => window.fbq('track', 'EstimaterSuccessLoaded');
export const claimTaxCreditEvent = () => window.fbq('track', 'ClaimTaxCredit');

export const step1IntakeFormLoadedEvent = () => window.fbq('track', 'Step1 - IntakeFormLoaded');
export const step1IntakeFormCompletedEvent = () => window.fbq('track', 'Step1 - IntakeFormCompleted');

export const step2BasicInfoFormLoadedEvent = () => window.fbq('track', 'Step2 - BasicInfoFormLoaded');
export const step2BasicInfoFormCompletedEvent = () => window.fbq('track', 'Step2 - BasicInfoFormCompleted');

export const step3PartOneSickLeaveCareYouRequiredLoadedEvent = () => window.fbq('track', 'Step3 - PartOneSickLeaveCareYouRequiredLoaded');
export const step3PartOneSickLeaveCareYouRequiredCompletedEvent = () => window.fbq('track', 'Step3 - PartOneSickLeaveCareYouRequiredCompleted');

export const step4PartOneSickLeaveCareYouProvidedToAnotherLoadedEvent = () => window.fbq('track', 'Step4 - PartOneSickLeaveCareYouProvidedToAnotherLoaded');
export const step4PartOneSickLeaveCareYouProvidedToAnotherCompletedEvent = () => window.fbq('track', 'Step4 - PartOneSickLeaveCareYouProvidedToAnotherCompleted');

export const step5PartThreeSickLeaveCareYouRequiredLoadedEvent = () => window.fbq('track', 'Step5 - PartThreeSickLeaveCareYouRequiredLoaded');
export const step5PartThreeSickLeaveCareYouRequiredCompletedEvent = () => window.fbq('track', 'Step5 - PartThreeSickLeaveCareYouRequiredCompleted');

export const step6PartThreeSickLeaveCareYouProvidedToAnotherLoadedEvent = () => window.fbq('track', 'Step6 - PartThreeSickLeaveCareYouProvidedToAnotherLoaded');
export const step6PartThreeSickLeaveCareYouProvidedToAnotherCompletedEvent = () => window.fbq('track', 'Step6 - PartThreeSickLeaveCareYouProvidedToAnotherCompleted');

export const step7ClaimDependentLoadedEvent = () => window.fbq('track', 'Step7 - ClaimDependentLoaded');
export const step7ClaimDependentCompletedEvent = () => window.fbq('track', 'Step7 - ClaimDependentCompleted');

export const step8PartTwoFamilyLeaveCareYouProvidedToDependentLoadedEvent = () => window.fbq('track', 'Step8 - PartTwoFamilyLeaveCareYouProvidedToDependentLoaded');
export const step8PartTwoFamilyLeaveCareYouProvidedToDependentCompletedEvent = () => window.fbq('track', 'Step8 - PartTwoFamilyLeaveCareYouProvidedToDependentCompleted');

export const step9PartFourFamilyLeaveCareYouRequiredOrProvidedLoadedEvent = () => window.fbq('track', 'Step9 - PartFourFamilyLeaveCareYouRequiredOrProvidedLoaded');
export const step9PartFourFamilyLeaveCareYouRequiredOrProvidedCompletedEvent = () => window.fbq('track', 'Step9 - PartFourFamilyLeaveCareYouRequiredOrProvidedCompleted');

export const step10ClientServicesAgreementLoadedEvent = () => window.fbq('track', 'Step10 - ClientServicesAgreementLoaded');
export const step10ClientServicesAgreementCompletedEvent = () => window.fbq('track', 'Step10 - ClientServicesAgreementCompleted');

export const step11PreQualifiedLoadedEvent = () => window.fbq('track', 'Step11 - PreQualifiedLoaded');
export const step11PreQualifiedCompletedEvent = () => window.fbq('track', 'Step11 - PreQualifiedCompleted');

export const step12GovernmentIdentificationLoadedEvent = () => window.fbq('track', 'Step12 - GovernmentIdentificationLoaded');
export const step12GovernmentIdentificationCompletedEvent = () => window.fbq('track', 'Step12 - GovernmentIdentificationCompleted');

export const step13InformationValidationLoadedEvent = () => window.fbq('track', 'Step13 - InformationValidationLoaded');
export const step13InformationValidationCompletedEvent = () => window.fbq('track', 'Step13 - InformationValidationCompleted');

export const step14ESignAuthorizationFormLoadedEvent = () => window.fbq('track', 'Step14 - ESignAuthorizationFormLoaded');
export const step14ESignAuthorizationFormCompletedEvent = () => window.fbq('track', 'Step14 - ESignAuthorizationFormCompleted');

export const step15LetUsGetToWorkNowLoadedEvent = () => window.fbq('track', 'Step15 - LetUsGetToWorkNowLoaded');
