import React, { useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import { colors } from '../config/env';
import { setWithExpiry, getWithExpiry } from '../component/utility';
import { step14ESignAuthorizationFormLoadedEvent, step14ESignAuthorizationFormCompletedEvent } from '../component/FacebookPixelEvents';

function ESignInstructions({ activeKey, setActiveKey, formData, setFormData })
{
    useEffect(() =>
    {
        step14ESignAuthorizationFormLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }

    }, [setFormData]);

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>Instantly ESign Your IRS Transcript Authorization Form!</h2>

                <div className="mt-4 d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969103956?h=885a5ac128&title=0&byline=0&portrait=0" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>
                <br></br>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    We need to pull your 2020 and 2021 IRS Transcripts as part of this process. This form authorizes the IRS to provide us with these transcripts so we can calculate and optimize your Tax Credits. By answering a few questions and providing us with your Driver License as part of the proof of identity requirements. We will be instantly able to generate this document for EACH of the parties involved to sign.
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    This form will be instantly emailed and or sent via SMS to the email and cellphone number provided.
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    You can send this form via email or SMS to any of the parties involved to sign. Once you sign this form our team will Efile with the IRS. It can take up to 15 business days for the IRS to provide us with this information.
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    We will update you via email or SMS when we have received this information and validated the available tax credits.
                </p>

                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>Please Check Your Email or SMS For This Document to Sign!</h2>

                <ul>
                    <li>Please check your email for this document to sign. </li>
                    <li>If the email is not in your inbox please check your spam folder. </li>
                    <li>It can take up to 15 minutes for you to receive this email. </li>
                    <li>You will also receive this document link via SMS on the phone number provided in the previous step. </li>
                    <li>Look for the email with the subject: <strong>"Signature Request : Automated Tax Credits"</strong></li>
                    <li>Click the button inside this email or Click the link in the SMS you received. </li>
                </ul>

                <p>Once you sign the document it will take you to the confirmation page, if you have signed the document and you did not see the confirmation page please click the button below.</p>

                <Row style={{ justifyContent: 'center', marginTop: '12px' }}>
                    <Button
                        className="normal-button"
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                        onClick={() =>
                        {
                            step14ESignAuthorizationFormCompletedEvent();
                            setActiveKey(Number(activeKey) + 1);
                        }}
                    >
                        Yes, I have ESigned This Authorization
                    </Button>
                </Row>
            </div>
        </div>
    );
}

export default ESignInstructions;
