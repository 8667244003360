import React from 'react';
import InputMask from 'react-input-mask';

const SSNInput = ({ mask, maskChar, placeholder, value, onChange }) =>
{
    return (
        <InputMask
            mask={mask}
            maskChar={maskChar}
            value={value}
            onChange={onChange}
        >
            {() => <input className="form-control" type="text" placeholder={placeholder} />}
        </InputMask>
    );
};

export default SSNInput;
