import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { day50 } from "../component/helper";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step8PartTwoFamilyLeaveCareYouProvidedToDependentLoadedEvent, step8PartTwoFamilyLeaveCareYouProvidedToDependentCompletedEvent } from '../component/FacebookPixelEvents';

function FormG({ activeKey, setActiveKey, formData, setFormData })
{
  const [error, setError] = useState({});

  useEffect(() =>
  {
    step8PartTwoFamilyLeaveCareYouProvidedToDependentLoadedEvent();

    const storedFormData = getWithExpiry("formData");
    if (storedFormData)
    {
      setFormData(storedFormData);
    }
  }, [setFormData]);

  const submit = (e) =>
  {
    e.preventDefault();
    setError({});

    const initialError = {};
    if (!formData.S4_Q1) initialError.S4_Q1 = "Please select value";

    if (Object.values(initialError).find((e) => e))
    {
      setError(initialError);
    }
    else
    {
      step8PartTwoFamilyLeaveCareYouProvidedToDependentCompletedEvent();

      const newActiveKey = +activeKey + 1;
      setActiveKey(newActiveKey);
      setWithExpiry("activeKey", newActiveKey, 50000);
      setWithExpiry("formData", formData, 50000);
    }
  };

  return (
    <Form onSubmit={submit}>
      <Row>
        <div className="form-header">
          <div className="form-title">
            Number of days after December 31, 2020, and before April 1, 2021, you were unable to perform services as a self-employed individual because of certain coronavirus-related care you provided to a son or daughter (Don't enter more than 50 days).

            <p style={{fontWeight: "bold", marginTop: "10px"}}>
              See below for instructions
            </p>
          </div>
        </div>
      </Row>

      <Row className="date-select-picker">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
          {
            const updatedFormData = { ...formData, S4_Q1: e.target.value };
            setFormData(updatedFormData);
            setWithExpiry("formData", updatedFormData, 50000);
          }}>
            <option>Select Days</option>
            {day50.slice().reverse().map((val, idx) =>
            {
              return <option key={idx} value={val}>{val}</option>;
            })}
          </Form.Select>
          <p className="validation-error">{error.S4_Q1}</p>
        </div>
      </Row>

      <Row>
        <div className="form-button-group mt-3">
          <Button
            className="next-button"
            type="button"
            onClick={() =>
            {
              const newActiveKey = +activeKey - 1;
              setActiveKey(newActiveKey);
              setWithExpiry("activeKey", newActiveKey, 50000);
            }}
            style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
          >
            Back
          </Button>
          <Button className="next-button" type="submit" style={{ backgroundColor: colors.theme, borderColor: colors.theme, width: 100 }}>
            NEXT
          </Button>
        </div>
      </Row>

      <Col className="mt-4 upper-divider italics">
        <p>Select the number of days in the period from January 1, 2021, through March 31, 2021, that you were unable to perform services as an eligible self-employed individual because of certain coronavirus-related care you provided to a son or daughter whose school or place of care is closed or whose childcare provider is unavailable for reasons related to COVID-19.</p>
        <p>A son or daughter must generally be under 18 years of age or incapable of self-care because of a mental or physical disability.</p>
      </Col>
    </Form>
  );
}

export default FormG;
