import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Col } from "react-bootstrap";
import Question from "../component/Question";
import { isEmpty } from "../component/helper";
import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import { step1IntakeFormLoadedEvent, step1IntakeFormCompletedEvent } from '../component/FacebookPixelEvents';

function FormFirst({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() =>
    {
        step1IntakeFormLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};

        if (isEmpty(formData.S1_Q1_Selfemployed))
        {
            initialError.S1_Q1_Selfemployed = "Please select the answer";
        }

        if (isEmpty(formData.S1_Q2_Filed1040_tax))
        {
            initialError.S1_Q2_Filed1040_tax = "Please select the answer";
        }

        if (isEmpty(formData.S1_Q3_Affected))
        {
            initialError.S1_Q3_Affected = "Please select the answer";
        }

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        } else if (formData.S1_Q3_Affected === "No")
        {
            handleShow();
        }
        else
        {
            step1IntakeFormCompletedEvent();
            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", { ...formData }, 50000);
        }
    };

    const restartApplication = () =>
    {
        setActiveKey(+activeKey);
        handleClose();
    };

    return (
        <>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Question
                        title="Were you self-employed, a sole proprietor, a 1099 independent contractor, and/or a freelancer in 2020 and/or 2021? *"
                        groupName="S1_Q1_Selfemployed"
                        value={formData.S1_Q1_Selfemployed}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q1_Selfemployed: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q1_Selfemployed}</p>
                    <Question
                        title="Were you a small business owner who filed a 1040 tax return with attached Schedule C in 2020 and/or 2021? *"
                        groupName="S1_Q2_Filed1040_tax"
                        value={formData.S1_Q2_Filed1040_tax}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q2_Filed1040_tax: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q2_Filed1040_tax}</p>
                    <Question
                        title="Were you affected by the COVID-19 pandemic in 2020 and/or 2021? *"
                        groupName="S1_Q3_Affected"
                        value={formData.S1_Q3_Affected}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q3_Affected: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q3_Affected}</p>
                    <div className="next-button-div">
                        <Button
                            className="next-button"
                            type="submit"
                            style={{
                                backgroundColor: colors.theme,
                                borderColor: colors.theme,
                                width: 100,
                            }}
                        >
                            NEXT
                        </Button>
                    </div>
                </Form.Group>
            </Form>

            <Col className="mt-4">
                <h4>Intake Questions FAQ: </h4>

                <p>Refer to this additional information to answer any questions you may have about correctly answering these questions.</p>

                <p className="mt-4"><b>Eligible Self-Employed Individual </b></p>

                <p className="mt-4">You must be an eligible self-employed individual to claim the qualified sick and family leave equivalent credit. To be an eligible self-employed person, both of the following must be true.</p>

                <ol className="mt-4">
                    <li>You regularly carried on a trade or business within the meaning of section 1402 in 2021</li>
                    <li>You would have been eligible, had you been an employee of an employer (other than yourself), to receive qualified sick leave wages under the Emergency Paid Sick Leave Act or qualified family leave wages under the Emergency Family and Medical Leave Expansion Act.</li>
                </ol>

                <p className="mt-4">If you answer YES, to these questions then you can answer YES, on the above intake questions. </p>
                <p className="mt-4">If you are in doubt, answer YES, let our team pull your IRS Transcripts, and then validate your data based on what you submitted to the IRS in 2021. </p>

                <p className="mt-4"><b>Regularly carried on a trade or business.</b> An individual regularly carries on a trade or business for purposes of being an eligible self-employed person if he or she carries on a trade or business within the meaning of section 1402, or is a partner in a partnership carrying on a trade or business, within the meaning of section 1402. For more information, see section 1402(c).</p>
                <p className="mt-4"><b>Eligibility to receive qualified leave wages.</b> For Part I, if you were unable to perform services as a self-employed individual on a day between January 1, 2021, and March 31, 2021, for one or more of the following reasons, then you meet item (2) under Eligible Self-Employed Individual for that day.</p>

                <ul>
                    <li>You were subject to a federal, state, or local quarantine or isolation order related to COVID-19. </li>
                    <li>You were advised by a health care provider to self-quarantine due to concerns related to COVID-19. </li>
                    <li>You were experiencing symptoms of COVID-19 and seeking a medical diagnosis of COVID-19. </li>
                    <li>You were caring for an individual who was subject to a federal, state, or local quarantine or isolation order related to COVID-19. </li>
                    <li>You were caring for an individual who had been advised by a healthcare provider to self-quarantine due to concerns related to COVID-19. </li>
                    <li>You were caring for a son or daughter because the school or place of care for that child was closed or the childcare provider for that child was unavailable due to COVID-19 precautions.</li>
                </ul>

                <p className="mt-4"><b>For Part II,</b> if you were unable to perform services as a self-employed individual on a day between January 1, 2021, and March 31, 2021, for the following reason, then you meet item (2) under Eligible Self-Employed Individual for that day. </p>
                <ul>
                    <li>You were caring for a son or daughter because the school or place of care for that child was closed or the childcare provider for that child was unavailable due to COVID-19 precautions.</li>
                </ul>

                <p className="mt-4"><b>For Parts III and IV,</b> if you were unable to perform services as a self-employed individual on a day between April 1, 2021, and September 30, 2021, for one or more of the following reasons, then you meet item (2) under Eligible Self-Employed Individual for that day. </p>
                <ul>
                    <li>You were subject to a federal, state, or local quarantine or isolation order related to COVID-19. </li>
                    <li>You were advised by a health care provider to self-quarantine due to concerns related to COVID-19. </li>
                    <li>You were experiencing symptoms of COVID-19 and seeking a medical diagnosis of COVID-19. </li>
                    <li>You were seeking or awaiting the results of a diagnostic test for, or a medical diagnosis of COVID-19. </li>
                    <li>You were exposed to COVID-19 or were unable to work pending the results of a test or diagnosis. </li>
                    <li>You were obtaining immunization related to COVID-19.</li>
                    <li>You were recovering from any injury, disability, illness, or condition related to such immunization. </li>
                    <li>You were caring for an individual who was subject to a federal, state, or local quarantine or isolation order related to COVID-19. </li>
                    <li>You were caring for an individual who had been advised by a health care provider to self-quarantine due to concerns related to COVID-19. </li>
                    <li>You were caring for a son or daughter because the school or place of care for that child was closed or the childcare provider for that child was unavailable due to COVID-19 precautions. </li>
                    <li>You were accompanying an individual to obtain immunization related to COVID-19. </li>
                    <li>You were caring for an individual who was recovering from any injury, disability, illness, or condition related to the immunization.</li>
                </ul>

                <p className="mt-4"><b>Self-Employed Status:</b></p>
                <p className="mt-4">If you were self-employed in 2021, you could potentially qualify for the SETC. This includes sole proprietors who run businesses with employees, 1099 subcontractors, and single-member LLCs. You must have filed a “Schedule SE” or Self Employment Tax on your 1040 federal tax return.</p>
                <p className="mt-4">If you find the Income on Line 6 of your Schedule SE inside your tax returns to confirm your eligibility. You do NOT need to find your tax returns right now, you can review them only if you want to validate your eligibility. Our team will pull your IRS Transcripts for 2021 to confirm your eligibility as part of our intake process. This is why it can take up to 15 business days to confirm with the IRS. </p>
                <p className="mt-4">Line 6 on your Tax Return looks like this: </p>

                <img className="w-100" src="/Line6Example1.png" />

                <h4 className="mt-4">COVID Impacts: </h4>

                <p className="mt-4">Whether you battled COVID, experienced COVID-like symptoms, needed to quarantine, underwent testing or cared for a family member affected by the virus, the SETC could be your ﬁnancial relief. If the closure of your child’s school or daycare due to COVID restrictions forced you to stay home and impacted your work, we’re here to help.</p>

            </Col>


            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Application Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Dear Applicant, we regret to inform you that after a thorough review
                        of your application, you do not qualify for the Self-Employed Tax
                        Credit.
                    </p>
                    <p>
                        If you believe this decision was made in error, you are welcome to
                        reapply. Should you have any questions or need further assistance,
                        please do not hesitate to contact our support team at{" "}
                        <a href="mailto:help@automatedtaxcredits.com">
                            help@automatedtaxcredits.com
                        </a>
                        .
                    </p>
                    <p>Please click here to reapply:</p>
                    <Button
                        className="next-button"
                        onClick={restartApplication}
                        style={{
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,
                        }}
                    >
                        Restart Application
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FormFirst;
