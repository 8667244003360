import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import CustomCalendar from "./CustomCalendar";

import { addDays } from "date-fns";
import { Button, Col, Row } from 'react-bootstrap';

const DateDropDown = ({ title, maxValue, formData, onChange, startDay, endDay, error, highlightedDates, selectHighlighted }) =>
{
    const dates = [];
    const dateStyle = {
        paddingRight: "20px",
    };
    const dateSelect = {};
    const selectDatePanel = {
        padding: "0px 20px",
    };

    const selectBox = {
        paddingTop: "5px",
    };

    for (let i = (maxValue || 10); i >= 0; i--)
    {
        let prefix;
        if (i === 0)
        {
            prefix = "none";
        }
        else if (i === 1)
        {
            prefix = "1 day";
        }
        else
        {
            prefix = i + " days";
        }

        dates.push([i, prefix]);
    }

    const [selectedDates, setSelectedDates] = useState([]);
    const [highlightedDate, setHighlightedDate] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const itemsPerColumn = isMobile ? selectedDates.length : 5;

    const getGridTemplateColumns = () =>
    {
        const columns = isMobile ? 1 : Math.ceil(selectedDates.length / itemsPerColumn);
        return `repeat(${columns}, 1fr)`;
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: getGridTemplateColumns(),
        gap: '10px',
    };

    const getItemStyles = (index) =>
    {
        return {
            gridColumn: Math.floor(index / itemsPerColumn) + 1,
            gridRow: (index % itemsPerColumn) + 1,
        };
    };

    const updateMedia = () =>
    {
        setIsMobile(window.innerWidth < 600);
    };

    useEffect(() =>
    {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    const getDays = (days) =>
    {
        const selectedDay = [];

        for (let i = 1; i <= days; i++)
        {
            const prefix = i + " day";
            selectedDay.push([prefix]);
        }

        setSelectedDates(selectedDay);
        setHighlightedDate([...Array(selectedDay.length)]);
    };

    useEffect(() =>
    {
        onChange(highlightedDate);
        // eslint-disable-next-line
    }, [highlightedDate]);

    return (
        <div style={selectDatePanel}>
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
            <div>
                {/* <div style={dateRangeStyle}>{dateRange()}</div> */}
                <div style={selectBox}>
                    <Row>
                        <Col>
                            <Form.Select
                                className={dateSelect}
                                aria-label="Select days"
                                onChange={(e) => getDays(e.target.value)}
                            >
                                <option value="" defaultValue>Select days</option>
                                {dates.map((day, index) => (
                                    <option value={day[0]} style={dateStyle} key={index}>
                                        {day[1]}
                                    </option>
                                ))}
                            </Form.Select>
                            <p className="validation-error">{error}</p>
                        </Col>

                        {(highlightedDate.length > 0) && (
                            <Button
                                className="btn btn-warning"
                                onClick={() =>
                                {
                                    const hDates = [];
                                    for (let i = 0; i < highlightedDate.length; i++)
                                    {
                                        hDates.push(null);
                                    }

                                    setHighlightedDate(hDates);
                                }}
                                style={{ width: 100 }}
                            >
                                RESET
                            </Button>
                        )}

                    </Row>
                </div>
                <div style={gridStyle}>
                    {selectedDates.map((day, index) => (
                        <div key={index} style={getItemStyles(index)}>
                            <CustomCalendar
                                start={startDay}
                                end={endDay}
                                title={day}
                                key={index}
                                index={index}
                                setHighlightedDate={(e) =>
                                {
                                    const newDates = [];
                                    for (let i = 0; i < index; i++)
                                    {
                                        newDates.push(e[i]);
                                    }

                                    let selectedDate = e[index];
                                    newDates.push(selectedDate);

                                    for (let i = index + 1; i < highlightedDate.length; i++)
                                    {
                                        if (selectedDate)
                                        {
                                            selectedDate = addDays(selectedDate, 1);
                                            newDates.push(selectedDate);
                                        }
                                        else
                                        {
                                            newDates.push(highlightedDate[i]);
                                        }
                                    }

                                    setHighlightedDate([...newDates]);
                                }}
                                highlightedDate={highlightedDate}
                                error={error && !highlightedDate[index]}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DateDropDown;
