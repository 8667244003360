import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { textRegex, emailRegex, isEmpty } from "../component/helper";
import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import Misspeller from "../component/Misspeller";
import { colors, APP_BASE_URL, apiURL } from "../config/env";
import axios from "axios";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import FullScreenSpinner from '../component/FullScreenSpinner';
import queryString from "query-string";
import { step2BasicInfoFormLoadedEvent, step2BasicInfoFormCompletedEvent } from '../component/FacebookPixelEvents';

function FormA({ activeKey, setActiveKey, formData, setFormData })
{
    const [error, setError] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() =>
    {
        step2BasicInfoFormLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = async (e) =>
    {
        e.preventDefault();

        setLoading(true);
        setErrorMessage(null);
        setError({});

        const errors = {};
        if (isEmpty(formData.First_Name)) errors.First_Name = "Enter a value for this field";
        else if (textRegex.test(formData.First_Name) === false) errors.First_Name = "Name can only contain letters";

        if (isEmpty(formData.Last_Name)) errors.Last_Name = "Enter a value for this field";
        else if (textRegex.test(formData.Last_Name) === false) errors.Last_Name = "Name can only contain letters";

        if (isEmpty(formData.Email)) errors.Email = "Enter a value for this field";
        else if (emailRegex.test(formData.Email) === false) errors.Email = "Enter a valid email address. (eg: yourname@domain.com)";

        if (formData.Email !== formData.emailConfirm) errors.emailConfirm = "Emails do not match";
        if (formData.Phone?.length < 11) errors.Phone = "Enter a value for this field.";

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            setLoading(false);
            return;
        }

        const parsed = queryString.parse(window.location.search);
        const token = parsed.token;

        const taxData = {
            s1Q1: formData.S1_Q1_Selfemployed === "Yes",
            s1Q2: formData.S1_Q2_Filed1040_tax === "Yes",
            s1Q3: formData.S1_Q3_Affected === "Yes",
            firstName: formData.First_Name,
            lastName: formData.Last_Name,
            email: formData.Email,
            phone: parseInt(formData?.Phone),
            referralSource: formData.salespersonId,
            refereallURL: window.location.href,
            clientName: formData.Client_Name,
            estimated_value: formData.EstimatedValue,
            resume_url: `${APP_BASE_URL}/resume?token=${token}`
        };

        try
        {
            const emailExistsUri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/email_exists_xano?email=${formData.Email}`;
            const emailExists = (await axios.get(emailExistsUri)).data;

            if (emailExists)
            {
                const xanoUri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;
                const responseXano = await axios.get(xanoUri);
                const zohoRecordId = responseXano.data.zoho_record_id;

                const response = await axios.post(
                    `${apiURL}/update_existing/${zohoRecordId}`,
                    taxData
                );

                setLoading(false);

                if (response.status === 200)
                {
                    step2BasicInfoFormCompletedEvent();

                    setActiveKey(+activeKey + 1);
                    setWithExpiry("activeKey", Number(activeKey) + 1, 50000);
                    setWithExpiry("formData", formData, 50000);
                }
            }
            else
            {

                const response = await axios.post(
                    `${apiURL}/insert_deal`,
                    taxData
                );

                setLoading(false);

                if (response.status === 200)
                {
                    setLoading(true);

                    const zohoRecordId = response.data.data[0].details.id;
                    const xanoPayload = {
                        email: formData.Email,
                        zoho_record_id: zohoRecordId,
                        state: ''
                    };

                    const responseXano = await axios.post(
                        "https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status",
                        xanoPayload
                    );


                    setLoading(false);

                    step2BasicInfoFormCompletedEvent();

                    setActiveKey(+activeKey + 1);
                    setWithExpiry("activeKey", Number(activeKey) + 1, 50000);
                    setWithExpiry("formData", formData, 50000);
                }
            }
        }
        catch (error)
        {
            console.log('Server Error: ', error);
            setLoading(false);
            setErrorMessage(error?.response?.data?.message || error?.message);
        }
    };

    return (
        <Form onSubmit={submit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row className="pt-4">
                    <div>
                        <span>
                            <strong>Name*</strong>
                        </span>
                    </div>
                    <Col span={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                placeholder=""
                                onChange={(e) =>
                                    setFormData({ ...formData, First_Name: e.target.value })
                                }
                                value={formData.First_Name || ""}
                            />
                            <Form.Label className="small-font">First Name</Form.Label>
                            <p className="validation-error">{error.First_Name}</p>
                        </Form.Group>
                    </Col>
                    <Col span={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                placeholder=""
                                onChange={(e) =>
                                    setFormData({ ...formData, Last_Name: e.target.value })
                                }
                                value={formData.Last_Name || ""}
                            />
                            <Form.Label className="small-font">Last Name</Form.Label>
                            <p className="validation-error">{error.Last_Name}</p>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                            <strong>
                                Email*{" "}
                                <span
                                    style={{
                                        fontWeight: 100,
                                        fontStyle: "italic",
                                        fontSize: 14,
                                    }}
                                >
                                    Is your email correct, please make sure there is no typo in
                                    the email address. You can choose one from the suggested list
                                    if incorrect.
                                </span>
                            </strong>
                        </Form.Label>
                        <Misspeller
                            value={formData.Email}
                            onChange={(Email) => setFormData({ ...formData, Email })}
                        />
                        <p className="validation-error">{error.Email}</p>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                            <span
                                style={{
                                    fontWeight: 100,
                                    fontStyle: "italic",
                                    fontSize: 14,
                                }}
                            >
                                Please confirm your email address, and enter it again.{" "}
                            </span>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Confirm Email"
                            onChange={(e) =>
                                setFormData({ ...formData, emailConfirm: e.target.value })
                            }
                            value={formData.emailConfirm || ""}
                        />
                        <p className="validation-error">{error.emailConfirm}</p>
                    </Form.Group>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <strong style={{ marginBottom: 5 }}>Phone* </strong>
                    <PhoneInput
                        country={"us"}
                        onlyCountries={["us"]}
                        value={formData.Phone || ""}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onChange={(e) => setFormData({ ...formData, Phone: e })}
                    />
                    <p className="validation-error">{error.Phone}</p>
                </Row>

                <Row>
                    <span>
                        By submitting this form, you are providing consent to be contacted by Automated Tax Credits and its partners and to receive messages including contact through automatic dialing systems or text message. As per the{" "}
                        <a
                            href="https://automatedtaxcredits.com/terms-and-conditions/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://automatedtaxcredits.com/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        . Message and data rates may apply. You may also text "STOP" any time to opt-out of our text messaging service.
                    </span>
                </Row>

                {errorMessage && (
                    <Row>
                        <Alert variant="danger" onClose={() =>
                        {
                            setErrorMessage(null);
                        }} dismissible className="mt-3">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    </Row>
                )}

                <Row>
                    <div className="form-button-group mt-3">
                        <Button
                            className="next-button"
                            type="button"
                            onClick={() => setActiveKey(+activeKey - 1)}
                            style={{
                                backgroundColor: colors.back,
                                borderColor: colors.back,
                                width: 100,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="next-button"
                            type="submit"
                            style={{
                                backgroundColor: colors.theme,
                                borderColor: colors.theme,
                                width: 100,
                            }}
                        >
                            NEXT
                        </Button>
                    </div>
                </Row>

                {loading && (
                    <Row>
                        <FullScreenSpinner />
                    </Row>
                )}
            </Form.Group>
        </Form>
    );
}

export default FormA;
