import React, { useState, useRef, useEffect } from "react";
import { Row, Form, Button, ListGroup, Table, Col, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Misspeller from "../component/Misspeller";
import { colors, apiURL } from "../config/env";
import axios from "axios";
import { isEmpty } from '../component/helper';
import { setWithExpiry, getWithExpiry } from "../component/utility";
import FullScreenSpinner from '../component/FullScreenSpinner';
import { step10ClientServicesAgreementLoadedEvent, step10ClientServicesAgreementCompletedEvent } from '../component/FacebookPixelEvents';

function ClientServicesAgreement({ activeKey, setActiveKey, formData, setFormData, skipped })
{
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
    const termsRef = useRef(null);
    const termsEndRef = useRef(null);

    useEffect(() =>
    {
        step10ClientServicesAgreementLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    // useEffect(() =>
    // {
    //     const scrollToBottom = () =>
    //     {
    //         const container = termsEndRef.current;
    //         if (container)
    //         {
    //             container.scrollTop = container.scrollHeight;
    //         }
    //     };

    //     activeKey === 10 && scrollToBottom();
    // }, [activeKey]);

    const handleBackClick = () =>
    {
        if (formData.claimDependent === "no")
        {
            setActiveKey(+activeKey - 3);
        }
        else
        {
            setActiveKey(+activeKey - 1);
        }
    };

    // const handleScroll = () =>
    // {
    //     const bottom = termsRef.current.scrollHeight - termsRef.current.scrollTop <= termsRef.current.clientHeight + 1;
    //     setIsScrolledToBottom(bottom);
    // };

    // useEffect(() =>
    // {
    //     if (termsRef.current)
    //     {
    //         termsRef.current.addEventListener('scroll', handleScroll);
    //     }
    //     return () =>
    //     {
    //         if (termsRef.current)
    //         {
    //             termsRef.current.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, []);

    useEffect(() =>
    {
        const currentDate = new Date().toISOString().split("T")[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            Effective_Date: currentDate,
            Client_Name: `${formData.First_Name} ${formData.Last_Name}`
        }));
    }, [setFormData, formData.First_Name, formData.Last_Name]);

    const handleAcceptTerms = () => setTermsAccepted(!termsAccepted);

    const listStyles = { border: "unset" };

    const submit = async (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};
        if (isEmpty(formData.Street_Address)) initialError.Street_Address = "Enter a value for this field";
        if (isEmpty(formData.City)) initialError.City = "Enter a value for this field";
        if (isEmpty(formData.State)) initialError.State = "Enter a value for this field";
        if (isEmpty(formData.Zip_Code)) initialError.Zip_Code = "Enter a value for this field";

        if (Object.keys(initialError).length > 0)
        {
            setError(initialError);
            return;
        }

        try
        {
            const S3_Q1_filedNames = await generateFieldNames(formData.S3_Q1, "S3_Q1");
            const S3_Q2_filedNames = await generateFieldNames(formData.S3_Q2, "S3_Q2");
            const S4_Q2_filedNames = await generateFieldNames(formData.S4_Q2, "S4_Q2");
            const S4_Q3_filedNames = await generateFieldNames(formData.S4_Q3, "S4_Q3");

            const taxData = {
                s1Q1: formData.S1_Q1_Selfemployed === "Yes",
                s1Q2: formData.S1_Q2_Filed1040_tax === "Yes",
                s1Q3: formData.S1_Q3_Affected === "Yes",
                firstName: formData.First_Name,
                lastName: formData.Last_Name,
                email: formData.Email,
                phone: parseInt(formData?.Phone),
                s3Q1: formData?.S3_Q1?.length,
                s3Q2: formData.S3_Q2?.length,
                s4Q1: parseInt(formData?.S4_Q1),
                s4Q2: formData?.S4_Q2?.length,
                s4Q3: formData?.S4_Q3?.length,
                s5Q1: parseInt(formData?.S5_Q1),
                referralSource: formData.salespersonId,
                refereallURL: window.location.href,
                clientName: formData.Client_Name,
                effectiveDate: formData.Effective_Date,
                streetAddress: formData.Street_Address,
                city: formData.City,
                state: formData.State,
                zipCode: formData.Zip_Code,
                claimDependent: formData.claimDependent,
                stage: 'PreQualComplete',
                filingStatus: formData.TaxFilingStatus
            };

            Object.assign(taxData, ...S3_Q1_filedNames);
            Object.assign(taxData, ...S3_Q2_filedNames);
            Object.assign(taxData, ...S4_Q2_filedNames);
            Object.assign(taxData, ...S4_Q3_filedNames);

            try
            {
                setLoading(true);

                const statePayload = {
                    "email": formData.Email,
                    "state": formData.State || ""
                };

                await axios.patch("https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status", statePayload);

                const xano_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;
                const resposne_xano = await axios.get(xano_uri);
                const zoho_record_id = resposne_xano.data.zoho_record_id;

                const response = await axios.post(`${apiURL}/update_record/${zoho_record_id}`, taxData);
                if (response.status === 200)
                {
                    setLoading(false);

                    step10ClientServicesAgreementCompletedEvent();

                    const newActiveKey = Number(activeKey) + 1;
                    setActiveKey(newActiveKey);
                    setWithExpiry("activeKey", newActiveKey, 50000);
                    setWithExpiry("formData", formData, 50000);
                }

            } catch (error)
            {
                console.error('Error:', error);
                setLoading(false);
                setErrorMessage(error.message);
            }

        } catch (error)
        {
            console.error("Error:", error);
        }
    };

    async function generateFieldNames(array, param)
    {
        const fieldNames = [];

        for (let i = 0; i < array?.length; i++)
        {
            const date = array[i];
            const dateString = formatDate(date);

            if (array.length === 1)
            {
                let fieldName;
                switch (param)
                {
                    case "S3_Q1":
                        fieldName = "S3_Q1_D1";
                        break;
                    case "S3_Q2":
                        fieldName = "S3_Q2_D1";
                        break;
                    case "S4_Q2":
                        fieldName = "S4_Q2_D1";
                        break;
                    case "S4_Q3":
                        fieldName = "S4_Q3_D1";
                        break;
                    default:
                        fieldName = `${param}_D1`;
                }
                fieldNames.push({ [fieldName]: dateString });
            } else
            {
                const tempObject = {};
                tempObject[`${param}_D${i + 1}`] = dateString;
                fieldNames.push(tempObject);
            }
        }

        return fieldNames;
    }

    function formatDate(date)
    {
        // Format date as mm/dd
        date = new Date(date);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${month}/${day}`;
    }

    return (
        <Form onSubmit={submit}>
            <Row>
                <Form.Group className="mb-3" style={{ marginTop: 30 }} controlId="clientName">
                    <Form.Label>
                        <strong>Client Name</strong>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={formData.Client_Name}
                        readOnly
                    />
                </Form.Group>
            </Row>

            {/* <Row>
                <Form.Group className="mb-3" controlId="effectiveDate">
                    <Form.Label>
                        <strong>Effective Date</strong>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={formData.Effective_Date}
                        readOnly
                    />
                </Form.Group>
            </Row> */}

            <Row>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>
                        <strong>
                            Email*{" "}
                            <span
                                style={{ fontWeight: 100, fontStyle: "italic", fontSize: 14 }}
                            >
                                Is your email correct, please make sure there is no typo in
                                the email address. You can choose one from the suggested list
                                if incorrect.
                            </span>
                        </strong>
                    </Form.Label>
                    <Misspeller
                        value={formData.Email}
                        onChange={(Email) => setFormData({ ...formData, Email })}
                    />
                    <p className="validation-error">{error.Email}</p>
                </Form.Group>
            </Row>

            <Row style={{ marginBottom: 10 }}>
                <Col lg={6} md={12}>
                    <strong style={{ marginBottom: 5 }}>Phone* </strong>
                    <PhoneInput
                        country={"us"}
                        onlyCountries={["us"]}
                        value={formData.Phone} // Make sure formData.Phone is initialized properly
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onChange={(e) => setFormData({ ...formData, Phone: e })}
                    />
                    <p className="validation-error">{error.Phone}</p>
                </Col>

                <Col lg={6} md={12}>
                    <div>
                        <span>
                            <strong>Tax Filing Status</strong>
                        </span>
                    </div>

                    <Form.Group className="mb-3" >
                        <Form.Select
                            onChange={(e) =>
                                setFormData({ ...formData, TaxFilingStatus: e.target.value })
                            }
                        >
                            <option value="Single">Single</option>
                            <option value="Married Filing Jointly">Married Filing Jointly</option>
                            <option value="Married Filing Separately (MFS)">Married Filing Separately (MFS)</option>
                            <option value="Qualifying Widow (QW)">Qualifying Widow (QW)</option>
                            <option value="Head of Household (HOH)">Head of Household (HOH)</option>
                            <option value="Not Sure">Not Sure</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Form.Group className="mb-3" controlId="streetAddress">
                    <Form.Label>
                        <strong>Client Street Address*</strong>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Street Address"
                        value={formData.Street_Address || ""}
                        onChange={(e) =>
                            setFormData({ ...formData, Street_Address: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.Street_Address}</p>
                </Form.Group>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="city">
                        <Form.Label>
                            <strong>City*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="City"
                            value={formData.City || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, City: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.City}</p>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>
                            <strong>State*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="State"
                            value={formData.State || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, State: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.State}</p>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3" controlId="zipCode">
                        <Form.Label>
                            <strong>Zip Code*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Zip Code"
                            value={formData.Zip_Code || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, Zip_Code: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.Zip_Code}</p>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <div
                    ref={termsRef}
                    style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}
                >
                    <h5 className="heading_emp" style={{ textAlign: 'center' }}>Terms & Service</h5>
                    <p>Last Updated: Jun, 25, 2024</p>
                    <br></br>
                    <p>This Client Services Agreement (“Agreement”) is entered into on this Effective Date and sets forth the terms and conditions whereby Automated Tax Credits, a Delaware limited liability company located at 8 The Green STE A, Dover, DE 19901 (“ATC”), agrees to provide Services for the Client (collectively the “Parties”) as stated below.</p>
                    <br></br>
                    <p>In consideration of the mutual promises and covenants contained in this Agreement, including the terms set forth in Schedule I, the adequacy and sufficiency of which is hereby acknowledged, the Parties agree to be bound as follows:</p>
                    <br></br>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            SERVICES
                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>The Client hereby exclusively engages ATC, and ATC hereby accepts such engagement, as an independent contractor to provide certain specialty and consulting services in relation to Family First Coronavirus Response Act (the “FFCRA”) and the American Rescue Plan Act (the “ARPA” the FFCRA and the ARPA are referred to herein collectively as the “Credits”) all on the terms and conditions set forth herein (the “Services”). In the performance of the Services, ATC shall advise Client on their eligibility for Credits and (1) download IRS Transcripts (2) Analyze transcripts using AI Analysis to maximize credits (3) Prepare documents to claim your tax credits (4) Provide client with documents for Esign (5) Efile documents with the IRS (6) Provide client with IRS Audit Protection (7) Provide client with additional services for bookkeeping, accounting, tax preparation and or Tax Resolution based on opportunities identified within the transcripts.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>During the term of this Contract, the Client is exclusively engaging ATC for the Services and the Client is excluded from entering into any other agreement with any other third party for the Services or similar services. The engagement of any party other than ATC for the Services or similar services shall be grounds for termination with Cause by ATC. The Client expressly acknowledges and understands that the Services cannot be relied upon to detect any errors, fraud, deficiencies or material weaknesses in the Client's own internal processes or controls, or any illegal conduct or acts that may exist with respect to the Client's business.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client acknowledges and understands that ATC is relying upon the information provided by the Client to ATC as factual and that ATC is not obligated to independently confirm the correctness or accuracy of such information provided by Client.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client acknowledges and understands that ATC will not provide, perform, undertake and implement any management functions or management decisions for the Client, nor shall ATC have any responsibility or authority to make, undertake and/or implement any management or business decisions for the Client in any capacity, including but not limited to any decisions based on or relating to the Services (the “Management Decisions”).</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>Unless otherwise set forth in this Agreement, ATC shall furnish, at its own expense, the equipment, supplies, and other materials used to perform the Services. The Client shall provide ATC with reasonable access to its premises, equipment, and other support to the extent necessary to perform the Services.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Parties agree that any materials or information provided by ATC to the Client in connection with the Services, including but not limited to financial information, insights, suggestions, advice and recommendations (the “Services Information”), are intended solely for Client's internal business use and are not intended for use by or disclosure to any third parties. For this reason, the Parties understand and agree that any Services Information provided by ATC during the Term of this Agreement will not include any disclosures that may be required by Generally Accepted Accounting Procedures (“GAAP”) for external use financial statements issued to third parties, nor shall ATC have any responsibility or obligation to audit, review or express any opinion regarding any Services Information provided to Client.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client acknowledges and consents to executing an IRS Form 8821 provided by ATC for the purpose of tracking the status of the Credits with the IRS promptly upon receipt but in no case more than five (5) business days after receipt. ATC will provide timing updates to the Client when available.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client acknowledges and consents to electronically executing an IRS Form 8821 provided by ATC for the purpose of tracking the status of the Credits with the IRS promptly upon receipt. ATC will provide timing updates to the Client when available.</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            TERM
                            <p>The term of this Agreement shall commence on the Effective Date and shall continue until terminated in accordance with this Agreement (the “Term”).</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            FEES AND EXPENSES

                            <ListGroup as="ol" numbered>
                                <ListGroup.Item as="li" style={listStyles}>
                                    <span>After the Client receives the tax credit analysis related to the Credits, including, without limitation, the offset of tax liability from the IRS or other taxing authorities (the “Client Receipt”), ATC's fee structure is based on the difference between the owed balances and the Self-Employed Tax Credit (SETC) available to the Client. Below is a detailed breakdown of our fee structure to provide clarity and transparency.</span>

                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th colSpan={3} className="text-center">Document Processing and Tax Form Preparation Fee</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Description of qualification of fee</th>
                                                <th>Fee amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Pre-paid</td>
                                                <td>If a client owes the IRS more than or equal to ($) the calculated refund amount, ATC will request that the client pay's ATC prior to signing and receiving their documents.</td>
                                                <td>15% of total Tax Credit</td>
                                            </tr>
                                            <tr>
                                                <td>Post-paid</td>
                                                <td>If a client owes the IRS less than($) the calculated refund amount, and the remaining amount is more than or equal to 20% of the total refund amount, then the fee will be deducted from the refund amount.</td>
                                                <td>20% of total Tax Credit</td>
                                            </tr>
                                            <tr>
                                                <td>Document Processing Fee</td>
                                                <td>A document processing fee is applied to all accounts to pay the associated fees and charges for document preparation or fees associated with Esign and document analysis, or preparation. </td>
                                                <td>$199 one-time applied to all accounts. </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>If the client is not qualified to receive any tax credits, there will be no fees charged. </td>
                                                <td>No fees charged.</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <p>Pre-paid is defined as paying your ATC service fees at the document preparation stage prior to submitting the documents to the IRS. Clients are provided with a limited-time discount for pre-paying their fees. Any discounts or incentives are void if payment in full has not been received prior to documentation being submitted to the IRS.</p>
                                    <br></br>
                                    <p>Post-paid is defined as paying your ATC service fees from your IRS Tax Credit which is handled by our appointed intermediary for payment disbursement. If the intermediary is not used for payment by the IRS for any reason the client is still liable to pay service fees, and interest as per the agreement. The Client Authorizes ATC to debit the client's preferred payment method until all fees are paid.  Any discounts or incentives are void if payment in full has not been received prior to documentation being submitted to the IRS.</p>
                                    <br></br>
                                    <p>For point of clarity, ATC is under no obligation to file for Credits and may refuse to move forward with a Client if, without limitation, ATC believes that the amount of Credit is too small to justify the work or the Client has outstanding tax liabilities that might impair ATC's ability to collect the payments.</p>
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>Client agrees to pay the Fees by authorizing ATC to debit ACH directly from Client's bank account. ATC shall have the right to file a UCC-1 lien filing on Client solely to secure Fees due and payable to ATC.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>Should Client fail to sign and return to ATC the specified documents within thirty (30) days after delivery of a completed analysis package showing Client is entitled to the Credits, (requiring only that the client sign and return specified documents), then Client shall be obligated to pay ATC one hundred percent (100%) of the Fees ATC would have been entitled to had Client filed the necessary documents with the IRS.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>Fees not paid by Client within thirty (30) calendar days after Client's receipt of the Credits will bear a late fee equal to one and one-half percent (1.5%) of the outstanding Fee amount per month. Should the Client fail to timely pay any Fees, ATC reserves the right to terminate this Agreement and/or withhold from providing any additional Services until such Fees are paid.</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            CLIENT SELF ATTESTATION

                            <p>Client (and all entities listed on the first page of this Agreement) acknowledges and understands that (i) ATC is relying on the information provided by Client as truthful and complete and that the Client has not omitted or altered any facts or information, (ii) the information provided to ATC by Client will be used in connection with determining Client's eligibility for the Credits, (iii) any reference to “Client” below includes entities that are treated as a single employer with Client under Section 52(a) or (B) of the Internal Revenue Code (the “Code”) or Section 414(m) or (o) of the Code, and (iv) it has listed all entities that are under the same majority ownership as the primary client entity signing above (even if the companies have unrelated businesses) and that the authorized signer of the Client agrees that by signing this Agreement, they are signing on behalf of all the client related entities as well.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            RELATIONSHIP OF THE PARTIES

                            <p>ATC is an independent contractor of the Client, and this Agreement shall not be construed to create any association, partnership, joint venture, employee, or agency relationship between ATC and the Client for any purpose. Except as authorized by the Client in writing, ATC shall have no authority to bind the Client and ATC shall not make any agreements or representations on the Client's behalf without the Client's prior written consent.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            IN WITNESS WHEREOF

                            <p>the Parties hereto have executed this Agreement as of the Effective Date. ACCEPTED AND AGREED:</p>
                        </ListGroup.Item>
                    </ListGroup>

                    <p className="text-center" style={{ fontWeight: "bold" }}>Schedule I: Terms and Conditions</p>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            CONFIDENTIALITY

                            <p>ATC acknowledges that ATC will have access to information that is treated as confidential and proprietary by the Client and is not generally available to the public, including the Client’s financial and personnel information (collectively, the “Confidential Information”). ATC agrees to treat all Confidential Information as confidential and not to disclose Confidential Information to third parties except as required in the performance of the Services, and such third parties shall also be bound by the terms of this section. Confidential Information shall not include information that is or becomes generally available to the public other than through breach of this Agreement or is communicated to ATC by a third party that had no confidentiality obligations with respect to such information. Nothing herein shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to the valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of disclosure required by such law, regulation, or order.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            REPRESENTATIONS AND WARRANTIES

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    ATC represents and warrants to the Client that:

                                    <ol type="a">
                                        <li>it has the full right, power, and authority to enter into this Agreement and to perform its obligations hereunder;</li>
                                        <li>entering into this Agreement does not and will not conflict with or result in any breach or default under any other agreement to which ATC is subject;</li>
                                        <li>ATC has the required skill, experience, and qualifications to perform the Services and will perform the Services in a professional manner in accordance with generally recognized industry standards for similar services. Notwithstanding the foregoing, ATC expressly declares that it is not a legal or financial firm and is not, and does not represent itself as, a licensed or registered law, accounting, financial, or tax firm; and</li>
                                        <li>the execution of this Agreement by its representative whose signature is set forth at the end hereof has been duly authorized by all necessary corporate action.</li>
                                    </ol>
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    The Client hereby represents and warrants to ATC that:

                                    <ol type="a">
                                        <li>it will provide only truthful information to ATC regarding Client’s business, will not omit material facts or information, and seeks ATC’s services only for lawful purposes.</li>
                                        <li>it will use its best efforts to cooperate with ATC, including timely responding to inquiries, information requests, and communications from ATC;</li>
                                        <li>upon reasonable request by ATC, it will provide ATC with access to the Client’s financial records and documents for the purpose of determining the Fees due and payable to ATC under this Agreement;</li>
                                        <li>it has not previously filed to claim Credits with the IRS, either directly or indirectly.</li>
                                        <li>entering into this Agreement does not and will not conflict with or result in any breach or default under any other agreement to which the Client is subject;</li>
                                        <li>it has the full right, power, and authority to enter into this Agreement and to perform its obligations hereunder; and</li>
                                        <li>the execution of this Agreement by its representative whose signature is set forth at the end hereof has been duly authorized by all necessary corporate action.</li>
                                        <li>if the Client provides or discloses any Services Information to third parties, the Client will not identify, represent or otherwise hold out such Services Information as the insights, suggestions, advice, recommendations and/or consulting services of ATC. Instead, the Client will: (i) identify such Services Information as the Client’s own internal business information, and (ii) inform any third parties that the information is not intended for use or reliance by such third parties.</li>
                                    </ol>
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            COVENANTS

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>The Client hereby agrees that it will, upon receipt of all necessary documents from ATC, promptly and without delay file the necessary document with the IRS to receive the Credits.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client hereby agrees that if it fails to receive its Credits, because all or a portion of the Credits were applied to pay outstanding liabilities including, without limitation, unpaid taxes, interest, penalties or the like, ATC is still entitled to prompt payment of its full Fee as if the Credits had been received.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client hereby agrees that it will promptly and without delay notify ATC when Credits are received from the IRS.</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>The Client hereby agrees to provide the necessary authorizations to allow ATC to access certain Client tax transcripts to monitor the status of the Credits directly with the IRS.</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            INDEMNIFICATION

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>ATC Indemnification Duties. ATC shall defend, indemnify, and hold harmless the Client, including its officers, directors, employees, agents, successors and assigns from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, or expenses of whatever kind (including reasonable attorneys’ fees) arising out of or resulting from (1) bodily injury, death of any person, or damage to real or tangible personal property resulting from ATC’s intentional acts or omissions, and (2) ATC’s breach of any representation, warranty, or obligation under this Agreement.</ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    Client Indemnification Duties. The Client shall defend, indemnify, and hold harmless ATC, including its officers, directors, members, employees, agents, successors and assigns from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, or expenses of whatever kind (including reasonable attorneys’ fees) arising out of or resulting from:
                                    (a) bodily injury, death of any person, or damage to real or tangible personal property resulting from the Client’s intentional acts or omissions;
                                    (b) the Client’s ownership and operation of its business and any business or management decisions made or implemented by the Client, including but not limited to the Management Decisions (as defined above) and any other decisions based on or relating to the Services provided by ATC and/or any Services Information provided by ATC.
                                    (c) the Client’s use of any Services Information provided by ATC or the Client’s disclosure of any Services Information to third parties;
                                    (d) the Client’s breach of any contractual or legal obligation to any third party;
                                    (e) the Client’s breach of any representation, warranty, or obligation under this Agreement;
                                    (f) the Client’s failure to timely pay Fees as required by this Agreement and any actions taken by ATC to secure payment of such Fees by the Client; and
                                    (g) the Parties’ contractual relationship under this Agreement, except as otherwise provided in Section 4.1 above.
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            LIMITATION OF LIABILITY

                            <p>Neither party shall be liable to the other party for any consequential damages, including any loss of business profits, business interruption, or special damages, incidental, exemplary, punitive or indirect damages arising from or relating to this agreement, regardless of how cased or the theory of liability pursued. This limitation shall apply even if such party has been advised of or is aware of the possibility of such damages. Notwithstanding the foregoing, ATC’s liability to the Client shall be limited to the Fees paid.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            INSURANCE

                            <p>During the Term, ATC shall maintain in force insurance, as required by law.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            TERMINATION

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    This Agreement shall automatically terminate upon the completion of the Services and ATC’s receipt of the final payment for Fees, or shall terminate with written notice by ATC per this Section.,. ATC may terminate the Agreement immediately with Cause upon written notice to the other Party or without Cause upon thirty (30) days’ written notice to the other Party. “Cause” shall mean the Client’s (i) breach of any representation or warranty in this Agreement, (ii) breach of the exclusive nature of this Agreement as defined by Paragraph 1.2 of the Contract, (iii) Client’s failure to timely file for the Credits as defined by Paragraph 3.3 of the Contract, (iv) Client’s failure to timely file the form 8821 (as provided in Section 1.7), or (v) Client’s failure to timely pay any Fees as provided in Section 3 of the Contract. Unless otherwise set forth in this Agreement:
                                    (a) in the event of termination without Cause by ATC, no further Fees shall be paid by Client, and ATC shall not refund or owe any refunded Fees already paid by the Client; and
                                    (b) in the event of termination with Cause by ATC, the Client shall promptly pay all Fees contemplated under the Agreement within ten (10) days as if the breach had not occurred upon ATC’s final invoicing.
                                    (c) Upon termination of this Agreement for any reason, ATC shall promptly:
                                    1. deliver to the Client all equipment or other materials belonging to Client provided for ATC’s use by the Client; and
                                    2. deliver to the Client all tangible documents and materials (and any copies) containing Confidential Information;

                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>The terms and conditions of this Schedule I Section 7, and Sections 1, 4, 5, 10, and 11 shall survive the termination of this Agreement.</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            OTHER BUSINESS ACTIVITIES

                            <p> As an independent contractor, ATC may be engaged or employed in any other business, trade, profession, or other activity simultaneously while performing Services under this Agreement or for the Client.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            ASSIGNMENT

                            <p> Except as otherwise described in the Agreement, ATC shall not assign any rights, or delegate or subcontract any obligations, under this Agreement without the Client’s prior written consent. The Client may freely assign its rights and obligations under this Agreement to its successor as applicable. Subject to the limits on assignment stated above, this Agreement will inure to the benefit of, be binding on, and be enforceable against each of the Parties hereto and their respective successors and assigns.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            COSTS AND EXPENSES

                            <p> Client agrees that all costs, charges, and expenses, including reasonable attorney's fees incurred by ATC arising out of or in connection with any action, proceeding, or hearing, legal or quasi legal, or the preparation therefore, in any way affecting or pertaining to this Agreement, including, but not limited to collection of unpaid amounts, shall be promptly paid by the Client. If funds for the same are advanced by ATC, all such sums so advanced shall be due and payable on demand.</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            REMEDIES

                            <p> If ATC is required to institute any suit or legal action to enforce any of the terms of this Agreement, ATC shall be entitled to recover any such amounts as the court may judge as reasonable. Whether or not any court action is involved, and to the extent not prohibited by law, all reasonable expenses ATC incurs that in ATC’s opinion are necessary to protect its rights under the Agreement. The Client hereby irrevocably authorizes ATC at Client’s expense to exercise at any time any of the following powers in order to settle all of Client’s obligations hereunder: (a) collect all monies due with respect to the Credits, (b) receive, take, endorse, assign, deliver, accept, and deposit, in the name of either Client or ATC, any and all cash, checks, notes, drafts, remittances, and other instruments and documents, relating to the Credits. </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            GOVERNING LAW, JURISDICTION, AND VENUE

                            <p> This Agreement and all related documents are governed by, and construed in accordance with, the laws of the State of Illinois, without giving effect to the conflict of laws provisions thereof to the extent such principles or rules would require or permit the application of the laws of any jurisdiction other than those of the State of Illinois. Any action or proceeding, including arbitration, by either Party to enforce this Agreement shall be brought only in the State of Illinois The Parties hereby irrevocably submit to the exclusive jurisdiction of courts or arbitration in the State of Illinois and waive the defense of inconvenient forum to the maintenance of any action or proceeding in such venue. The prevailing party shall be entitled to reasonable costs and attorneys’ fees. </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            MISCELLANEOUS

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a “Notice”) shall be in writing and addressed to the Parties at the addresses set forth on the first page of this Agreement (or to such other address that may be designated by the receiving party from time to time in accordance with this Section). All Notices shall be delivered by personal delivery, nationally recognized overnight courier (with all fees prepaid), or certified or registered mail (in each case, return receipt requested, postage prepaid), with a copy by email. A Notice is effective when sent if the Party giving the Notice has complied with the requirements of this Section.
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    This Agreement, together with any other documents incorporated herein by reference, and related exhibits and schedules, constitutes the sole and entire agreement of the Parties to this Agreement with respect to the subject matter contained herein and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to such subject matter.
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    This Agreement may only be amended, modified, or supplemented by an agreement in writing signed by each party hereto, and any of the terms thereof may be waived only by a written document signed by each party to this Agreement or, in the case of waiver, by the party or parties waiving compliance.
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    Each of the Parties hereby waives the right to a trial by jury in any proceedings or litigation brought against the other of them with respect to this Agreement.
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    By signing this Agreement you consent to receive text messages (including messages sent by an auto-dialer) from ATC at the number provided for reasons including, without limitation, processing status and information and materials needed for project completion. Message and data rates may apply.
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>

                    <h4>Marketing and Communication</h4>
                    <p>Opt-In Consent</p>
                    <p>By using our services, you consent to receive communications from us, including but not limited to, text messages, emails, and phone calls, regarding our services and other information we believe may be of interest to you. Standard data and messaging rates may apply.</p>
                    <br></br>

                    <p>Phone Calls and Messages</p>
                    <p ref={termsEndRef}>You acknowledge and agree that we may contact you via phone calls and text messages for marketing purposes, including information about new services, promotions, and updates. You may opt out of receiving such communications at any time by following the instructions provided in the communication or by contacting us directly.</p>
                </div>
            </Row>

            {isScrolledToBottom && (
                <Row className="mt-3">
                    <Form.Group controlId="termsCheckbox">
                        <Form.Check
                            type="checkbox"
                            label={
                                <span>
                                    I accept the terms and conditions of this agreement,
                                    and accept that this digital agreement is binding
                                    I agree to the <a href="https://automatedtaxcredits.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank">Terms of Service</a> and <a href="https://automatedtaxcredits.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.
                                </span>
                            }
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                    </Form.Group>
                </Row>
            )}

            {errorMessage && (
                <Row>
                    <Alert variant="danger" onClose={() =>
                    {
                        setErrorMessage(null);
                    }} dismissible className="mt-3">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>
                            {errorMessage}
                        </p>
                    </Alert>
                </Row>
            )}

            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <div className="form-button-group">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={handleBackClick}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        Back
                    </Button>
                    <Button className="next-button" type="submit" disabled={!termsAccepted} style={{ backgroundColor: colors.theme, borderColor: colors.theme, width: 100 }}>
                        NEXT
                    </Button>
                </div>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}

        </Form>
    );
}

export default ClientServicesAgreement;
